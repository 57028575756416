import React, { useState } from "react"
import "./Start.scss"
import Button from "../common/Button/Button"
import Input from "../common/Input/Input"
import Select from "../common/Select/Select"
import classNames from "classnames"

// import {graphql, useStaticQuery} from "gatsby"
import {useTranslation} from "react-i18next";
import { StaticImage } from "gatsby-plugin-image"
import { useMediaQuery } from "../hooks/useMediaQuery.js"

const title = {
  clients: "start.title.clients",
  companies: "start.title.companies",
  partners: "start.title.partners",
  contacts: "start.title.partners",
  tariffs: "start.title.tariffs",
}

function Start({
                 cardType,
                 type,
                 startRef,
                 emailDisabled,
                 email,
                 setEmail,
                 setForm,
               }) {

  let isPageWide = useMediaQuery("(min-width: 1280px)")
  const {t} = useTranslation()

  const [company, setCompany] = useState("")
  const [city, setCity] = useState("")
  const [activity, setActivity] = useState("")
  const [employees, setEmployees] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  function submit(event) {
    event.preventDefault()
    setForm({
      cardType,
      page: type,
      company,
      city,
      employees,
      name,
      phone,
      email,
      activity,
    })
  }


  return (
    <section ref={startRef} className={classNames("start", `start--${type}`)}>
    <div className="startImage">
      {
        isPageWide && type === "companies" ? (
          <StaticImage
            src="./images/girl2.png"
            alt="girl"
            placeholder="blurred"
            layout="constrained"
            width={253}
            height={446}
          />
        ) : isPageWide && type === "clients" ? (
          <StaticImage
            src="./images/man.png"
            alt="man"
            layout="constrained"
            width={315}
            height={581}
          />
        ) : isPageWide && type === "partners" ? (
          <StaticImage
            src="./images/girl_v2.png"
            alt="girl"
            placeholder="blurred"
            layout="constrained"
            width={336}
            height={527}
          />
        ) : isPageWide && type === "tariffs" ? (
          <StaticImage
            src="./images/girl_practice.png"
            alt="girl"
            layout="constrained"
            width={253}
            height={446}
          />
        ) : null
      }
    </div>
      <h3>{t(title[type])}</h3>

      <form onSubmit={submit}>
        <Input
          required="required"
          value={company}
          key="Название организации"
          placeholder={t("start.inputs.company")}
          name="company"
          onChange={setCompany}
          autoComplete="company"
        />
        <Input
          required="required"
          value={city}
          key="Город"
          placeholder={t("start.inputs.city")}
          name="city"
          onChange={setCity}
          autoComplete="city"
        />
        {type === "partners" && (
          <Input
            name="activity"
            value={activity}
            key="Основной вид услуг"
            placeholder={t("start.inputs.activity")}
            onChange={setActivity}
          />
        )}
        {type !== "partners" && (
          <Select
            required="required"
            value={employees}
            key="Количество сотрудников"
            placeholder={t("start.inputs.employees")}
            name="employees"
            options={[t("start.amount.less_100"), t("start.amount.less_1000"), t("start.amount.more_1000")]}
            onChange={setEmployees}
            autoComplete="employees"
          />
        )}
        <Input
          required="required"
          value={name}
          key="Имя представителя"
          placeholder={t("start.inputs.name")}
          name="name"
          onChange={setName}
          autoComplete="name"
        />
        <Input
          required="required"
          value={phone}
          key="Контактный номер"
          placeholder={t("start.inputs.phone")}
          name="phone"
          onChange={setPhone}
          autoComplete="phone"
        />
        <Input
          required="required"
          value={email}
          key="E-mail"
          placeholder="E-mail"
          name="email"
          onChange={setEmail}
          autoComplete="email"
          disabled={emailDisabled ? "disabled" : ""}
        />

        <Button type="submit" color="white">
          {t("start.buttonSubmit")}
        </Button>
      </form>
    </section>
  )
}

export default Start
